@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../../../public/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-Light';
    src: url('../../../public/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../../../public/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../../../public/fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype');
}
