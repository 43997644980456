.quizz-container {
   display: flex;
   justify-content: center;
   margin-top: 6rem; 
   padding: 4rem;
   color: white;
   flex-wrap: wrap;
   @media screen and (max-width: 650px) {
        padding: 2rem;
        margin-top: 0rem;
   }
   @media screen and(max-width: 350px) {
        font-size: 0.8rem;
   }
}

// style={{
//     display: "flex",
//     justifyContent: "center",
//     marginTop: "6rem",
//     padding: "4rem",
//     color: "white",
//   }}