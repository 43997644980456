.bear-check {
    .mask {
        position: fixed;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background: #141414
    }

    .bear {
        width: 100%;
        background: transparent;
        transform: translate(0, 100%);

        &__swear {
            display: none;
            position: absolute;
            left: 105%;
            top: 0;
            background: #fff;
            font-weight: bolder;
            padding: 10px;
            border-radius: 8px;

            &:before {
                content: '';
                background: #fff;
                position: absolute;
                top: 90%;
                right: 70%;
                height: 30px;
                width: 30px;
                clip-path: polygon(0 100%, 100% 0, 50% 0);
                -webkit-clip-path: polygon(0 100%, 100% 0, 50% 0);
            }
        }

        &__wrap {
            width: 100px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-15%, -50%) rotate(5deg) translate(0, -75%);
            background: transparent;
        }

        &__arm-wrap {
            background: transparent;
            position: fixed;
            height: 30px;
            width: 90px;
            z-index: 4;
            top: 50%;
            left: 50%;
            transform: translate(0, -50%) rotate(0deg);
        }

        &__arm {
            background: transparent;
            transform-origin: left;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-35%, -50%) scaleX(1);
        }

        &__paw {
            background: #784421;
            border-radius: 100%;
            position: fixed;
            height: 30px;
            width: 30px;
            z-index: 10;
            top: 50%;
            left: 50%;
            transform-origin: right;
            transform: translate(80px, -15px) scaleX(0);
        }
    }

    .checkbox {
        border-radius: 50px;
        height: 100px;
        position: fixed;
        width: 200px;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        [type='checkbox'] {
            cursor: pointer;
            border-radius: 50px;
            position: absolute;
            outline: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            z-index: 10;
            height: 100%;
            width: 100%;
            margin: 0;
        }

        &__bg {
            background: #aaa;
            border-radius: 50px;
            height: 100%;
            width: 100%;
            z-index: 10;
        }

        &__indicator {
            background: transparent;
            height: 100%;
            width: 50%;
            border-radius: 100%;
            position: absolute;
            top: 0;
            left: 0;

            &:after {
                content: '';
                border-radius: 100%;
                height: 85%;
                width: 85%;
                background: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}