@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');


#start {
  flex: 1 !important
}

@media screen and (max-width: 1150px) {
  #start {
    flex: unset !important;
  }
  .game {
    flex: unset !important;
  }
}

.start-screen {
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-right: -30px;
  position: relative;
  background: url('../../../public/imagens/telettubies-background.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
  display: block;
}

.inactive {
  display: none;
}

.game {
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-right: -30px;
  position: relative;
  flex: 1;
}

.game {
  background: url('../../../public/imagens/telettubies-background.jpg') no-repeat center;
  background-size: cover;
  cursor: crosshair;
  overflow: hidden;
  margin-right: -30px
}

// .game:active .duck:active {
//   box-shadow: red 0px 0px 0px 3000px;
// }

.titulo {
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-align: center;
  top: 30%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  letter-spacing: 6px;
  font-family: 'VT323', monospace;
  font-size: 50px;
  color: #FFF;
  text-shadow:
    1px 0px 1px rgb(14, 14, 14), 0px 1px 1px rgb(32, 32, 32),
    2px 1px 1px rgb(14, 14, 14), 1px 2px 1px rgb(32, 32, 32),
    3px 2px 1px rgb(14, 14, 14), 2px 3px 1px rgb(32, 32, 32),
    4px 3px 1px rgb(14, 14, 14), 3px 4px 1px rgb(32, 32, 32),
    5px 4px 1px rgb(14, 14, 14), 4px 5px 1px rgb(32, 32, 32),
    6px 5px 1px rgb(14, 14, 14), 5px 6px 1px rgb(32, 32, 32),
    7px 6px 1px rgb(14, 14, 14);
}

.game h2 {
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-align: center;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  letter-spacing: 2px;
}

.descricao {
  font-weight: bold;
  text-align: center;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  letter-spacing: 2px;
  font-family: 'VT323', monospace;
  font-size: 26px;
  color: #FFF;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.duck {
  position: absolute;
  left: -150px;
  right: 0px;
  height: 0px;
  border-radius: 100%;
  z-index: 10;
  transform: translateZ(0);

  a {
    display: block;
    cursor: crosshair;
    transition: all 0.1s;
    background-size: cover;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}

@for $i from 1 through 10 {
  .duck:nth-child(#{$i}) {
    top: percentage(random(60)/100);
    // original: 6s 
    animation: fly random(10)+2s random(200)/10+s linear infinite;

    a {
      width: random(100) + 50 +px;
      animation: float random(20)/10+1.5+s infinite cubic-bezier(.58, .14, .46, .92);
      // background: no-repeat center url('../../../public/dieidiersFotos/diedier\ \('+random(107)+'\).jpg');
      background-size: cover;
      border-radius: 70px;
    }
  }
}

@keyframes fly {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes float {
  0% {
    transform: translateY(40%);
  }

  50% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(40%);
  }
}

.duck:target {
  animation: none;
}

.button-start {
  margin: 0;
  position: relative;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #2b2b2b;
  border: none;
  outline: none;
  color: #FFF;
  padding: 20px 40px 20px 40px;
  border-radius: 15px;
  z-index: 20;
  transition: background 1s;
  display: flex;

  .coin {
    position: relative;
    margin-left: -15px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background: url('https://i.imgur.com/OZChImx.png') left center;
    animation: play 0.8s steps(6) infinite;
  }

  @keyframes play {
    100% {
      background-position: -240px;
    }
  }
}

.button-start:hover {
  background-color: #ffffff;
  color: #2b2b2b;
  transition: background 1s;
  cursor: pointer;
}

.button-start:disabled,
.button-start[disabled] {
  background-color: #0b0050;
}

.button-start-disabled {
  margin-top: 20px;
  position: relative;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #2b2b2b;
  border: none;
  outline: none;
  color: #FFF;
  padding: 20px 40px 20px 40px;
  border-radius: 15px;
  z-index: 20;
  transition: background 1s;
  display: flex;
}

.button-text {
  font-size: 32px;
  letter-spacing: 3px;
  font-family: 'VT323', monospace;
}

.timer {
  font-size: 30px;
  font-family: 'Kanit', sans-serif;
  padding-right: 30px;
  padding-top: 20px;
  text-align: right;

  .clock {
    width: 30px;
    padding-left: 10px;
  }
}

.shots {
  font-size: 30px;
  font-family: 'Kanit', sans-serif;
  padding-right: 30px;
  text-align: right;

  .palmito-coin {
    width: 30px;
    padding-left: 10px;
  }
}

.end-screen {
  flex: 1;
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-right: -30px;
  position: relative;
  background: url('../../../public/imagens/telettubies-background.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
}

.resultado {
  text-align: center;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  letter-spacing: 2px;

  .palmito-coin {
    width: 55px;
  }

  .result {
    font-size: 80px;
    letter-spacing: 3px;
    font-family: 'Kanit', sans-serif;
    padding-left: 10px;
  }
}