.config-main {
    overflow: auto;
    height: 100%;
}

.config-profile-box {
    background-color: var(--app-bg);
    display: flex;
    flex-direction: row;
    min-height: 180px;
    margin-bottom: 90px;
    position: relative;

    .loader-img {
        position: absolute;
        background: rgba(0, 0, 0, .3);
        width: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .avatar {
        position: absolute;
        bottom: -100px;
        left: calc(50% - 80px);
    }

    img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 24px;
        cursor: pointer;
    }

    &-name {
        width: 45%;
        margin-left: 2rem;
        margin-right: 2rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 0.063rem solid var(--text-dark);

        p {
            width: 100%;
            color: var(--text-dark);
            font-family: 'OpenSans-Regular';
        }

        span {
            font-family: 'OpenSans-SemiBold';
            font-size: 1.75rem;
            color: var(--text-dark);
        }

        .icon-name {
            fill: var(--text-dark);
            width: 20px;
        }
    }



    .avatar {
        &::before {
            content: 'Mudar avatar';
            background: rgba(0, 0, 0, .3);
            width: 160px;
            height: 160px;
            border-radius: 50%;
            display: none;
            align-items: center;
            position: absolute;
            color: #fff;
            font-size: 1rem;
            justify-content: center;
            cursor: pointer;
            font-family: 'OpenSans-Bold';
        }

        &:hover {
            &::before {
                display: flex;
            }
        }

        .button-change-avatar {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: var(--app-bg);
            border: 0.063rem solid var(--text-dark);
            position: absolute;
            right: 15px;
            z-index: 1;
            cursor: pointer;
            text-align: center;
            justify-content: center;
            display: flex;

            .icon-change {
                width: 25px;
                fill: var(--text-dark);
            }
        }
    }
}

.theme-container {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;


    @media screen and (max-width: 368px) {
        justify-content: center;

    }

    &-title {
        width: 100%;
        color: var(--text-dark);
        font-family: 'OpenSans-Regular';
    }

    @mixin theme {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 2px solid #000;
        margin-bottom: 2rem;
    }

    .theme-dark {
        @include theme();
        background: #131a24;
    }

    .theme-pink {
        @include theme();
        background: #f8f1f1;
    }

    .theme-navy-dark {
        @include theme();
        background: #12172d;
    }

    .theme-indigo {
        @include theme();
        background: #f5f8fc;
    }
}