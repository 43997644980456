.login-screen {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    height: 100%;

    .bem-vindo {
        font-family: 'OpenSans-Bold';
        color: #fff
    }

    .fix-bear {
        width: 44px;
        height: 12px;
        background-color: #141414;
        transform: translate(-117px, -50px);
    }

    .lembrar {
        font-family: 'OpenSans-Bold';
        color: #fff;
        transform: translate(-72px, -91px);
        font-size: 14px;
    }

    .password-recover {
        font-family: 'OpenSans-Bold';
        color: #fff;
        transform: translate(120px, -50px);

        &:hover {
            cursor: pointer;
        }
    }

    .create-account {
        font-family: 'OpenSans-Bold';
        color: #fff;
        margin-top: 14px;
        font-size: 14px;
    }

    .create-account-link {
        color: #6D58FF;
    }

    .bear-check {
        transform: translate(-140px, -50px);

        .bear-check-div {
            transform: scale(0.2)
        }
    }

    .login-fields {
        width: 50%;
        text-align: center;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .error-msg {
            color: #FC8181;
        }
    }

    .login-animation {
        width: 50%;
    }


    .space-input {
        margin-bottom: 4rem;
    }

    @media only screen and (max-width: 900px) {
        .login-animation {
            position: absolute;
            bottom: 0;
            background: transparent;
            opacity: 0.5;
            width: unset;
        }

        .login-fields {
            width: 100%;
            z-index: 1;
        }
    }

    @media screen and (max-width: 460px) {
        .password-recover {
          font-size: 12px;
          transform: unset;
          margin-bottom: 20px;
          transform: translate(110px, -50px);
        }

        .create-account {
            font-size: 12px;
        }

        .lembrar {
            font-family: 'OpenSans-Bold';
            color: #fff;
            transform: translate(-72px, -110px);
            font-size: 14px;
        }

        .bear-check {
            transform: translate(-140px, -65px);
    
            .bear-check-div {
                transform: scale(0.2)
            }
        }

        .fix-bear {
            transform: translate(-117px, -70px);
        }
    
    }
}