.paginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .total {
        color: var(--text-dark);
        font-family: 'OpenSans-Regular';
    }

    .arrow-circle {
        width: 2.25rem;
        height: 2.25rem;
        // border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: unset;
        cursor: pointer;
    }

    .icon {
        width: 1rem;
        height: 1rem;
        fill: var(--text-dark);
    }

    button {
        &:disabled {
            cursor: not-allowed;
            // background-color: #ccc;
        }

        border: none;
    }

}