.nav-menu {
    display: none;
    width: 100%;
    // margin-top: 40px;
    // margin-bottom: 10px;
    height: auto;

    .chat-list-item {
        flex-direction: column;
    }


    nav {
        // width: 100%;
        background-color: var(--box-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        flex-wrap: wrap;
    }

    .logo {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        position: sticky;
        top: 0;
        z-index: 1;

        h1 {
            margin: 0;
            font-size: 20px;
            color: var(--text-dark);
            margin-left: 12px;
        }
    }

    .menu,
    #close {
        display: none;


    }

    body.expended #close {
        display: block;
        position: relative;
        z-index: 100;

    }

    body.expended .menu {
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: orange;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    i {
        font-size: 21px;
        padding: 4px;
    }

    i:hover {
        color: white;

    }

    .menu ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 30px;

    }

    .menu ul li {
        font-size: 21px;
        color: #fff;
        cursor: pointer;

    }

    .itens {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 1150px) {
    .nav-menu {
        display: unset;
    }
}

@media screen and (max-width: 540px) {
    .itens {
        width: 100%;
        justify-content: space-between;
    }
}