body {
    margin: 0;
    height: 100vh;
    background-color: hsl(0, 0%, 8%);
    // background: rgb(0, 0, 0);
    // background: linear-gradient(330deg, rgba(0, 0, 0, 1) 85%, rgba(145, 164, 40, 1) 100%) fixed;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-image: linear-gradient(145deg, rgb(128, 146, 28) -10%, #000 20%, #000000 100%);

    //   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //     sans-serif;
    //   -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }