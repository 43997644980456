.button-form {

    .icon-x {
        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    }

    --color: #fff;
    --background: #6D58FF;
    --height: 41px;
    --width: 160px;
    --icon: #fff;
    padding: 0;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    width: var(--width);
    letter-spacing: .5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color);
    line-height: var(--height);

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &:before {
        transform: translate(-50%, -50%);
        width: var(--before-w, var(--width));
        height: var(--before-h, var(--height));
        border-radius: var(--before-r, 7px);
        background: var(--background);
        transform-origin: 50% 50%;
        transition: width .3s, height .3s, border-radius .2s;
        transition-delay: .2s;
    }

    &:after {
        width: 16px;
        height: 16px;
        margin: -8px 0 0 -8px;
        background: var(--icon);
        z-index: 2;
        border-radius: 50%;
        transform: scale(0);
    }

    span {
        z-index: 1;
        display: block;
        position: relative;
        opacity: var(--span-o, 1);
        transform: scale(var(--span-s, 1));
        transition: opacity .3s, transform .2s;
        transition-delay: .2s;
    }

    .svg-success,
    .svg-error {
        width: 15px;
        height: 13px;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        stroke: var(--icon);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        transform: translate(-50%, -50%);
        stroke-dasharray: 17px;
        transition: stroke-dashoffset .3s ease;
    }

    .svg-error {
        stroke-dashoffset: var(--svg-error, 17px);
    }

    .svg-success {
        stroke-dashoffset: var(--svg-success, 17px);
    }

    &.processing {
        --before-w: 41px;
        --before-h: 41px;
        --before-r: 22px;
        --span-o: 0;
        --span-s: .6;
        --after-s: 1;
    }

    &.animation {
        &:before {
            animation: pulse 3s infinite linear forwards;
        }

        &:after {
            animation: dot 3s infinite linear forwards;
        }
    }

    &.finish-success {
        --svg-success: 34px;
    }

    &.finish-error {
        --svg-error: 34px;
    }
}

@keyframes pulse {
    4% {
        transform: translate(-50%, -50%) scaleX(1.1);
    }

    8% {
        transform: translate(-50%, -50%);
    }

    24%,
    56%,
    88% {
        transform: translate(-50%, -50%) scale(.8);
    }

    40%,
    72% {
        transform: translate(-50%, -50%) scale(1.2);
    }
}

@keyframes dot {
    8% {
        transform: scale(0);
    }

    16% {
        transform: scale(1) rotate(30deg) translateX(48px);
    }

    95% {
        transform: scale(1) rotate(1000deg) translateX(48px);
    }

    100% {
        transform: scale(.125) rotate(1050deg) translate(52px, -6px);
    }
}