.recover-screen {
  display: flex;
  align-items: center;
  height: 100%;

  .recuperar {
    font-family: "OpenSans-Bold";
    color: #fff;
  }

  .recuperar-instruction {
    font-family: "OpenSans-SemiBold";
    color: #fff;
  }

  .recover-fields {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-msg {
      color: #fc8181;
    }
  }

  .login-redirect-recover-pass {
    font-family: "OpenSans-Bold";
    color: #fff;
    transform: translate(-118px, -44px);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .recover-animation {
    width: 30%;
  }

  .space-input {
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 900px) {
    .recover-animation {
      position: absolute;
      bottom: 0;
      background: transparent;
      opacity: 0.5;
      width: unset;
    }

    .recover-fields {
      width: 100%;
      z-index: 1;
    }
  }

  @media screen and (max-width: 460px) {
    .login-redirect-recover-pass {
      font-size: 12px;
      transform: unset;
      margin-bottom: 20px;
    }
  }
}
