.container {
    flex: 1;
    color: white;
    .button-secondary {
        margin-right: 0;
    }
    &-wrapper-options {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;
        width: 100%;
        :hover {
            cursor: pointer;
        }
        &-option {
            margin-bottom: 1rem;
            padding-block: 1rem;
            display: flex;
            flex: 1;
            background-color: var(--app-bg);
            border-radius: 1rem;
            width: 100%;
            transition: 0.3s;
            // marginBottom: "1rem",
            // paddingBlock: "1rem",
            // display: "flex",
            // flex: 1,
            // background: "#060415",
            // borderRadius: "1rem",
            // width: "100%",
        }
        &-option--correct-option {
            background-color: rgb(57, 184, 57);
        }
        &-option--wrong-option {
            background-color: rgb(248, 74, 74);
        }
        
    }
}