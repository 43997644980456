.register-screen {
  display: flex;
  align-items: center;
  height: 100%;

  .register {
    font-family: "OpenSans-Bold";
    color: #fff;
    margin: 0;
  }

  .register-instruction {
    font-family: "OpenSans-SemiBold";
    color: #fff;
  }

  .register-instruction-div {
    width: 50%;
    margin-bottom: 40px;
  }
  .register-fields {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-msg {
      color: #fc8181;
    }
  }

  .email-register {
    width: 50%;
  }

  .login-redirect-register {
    font-family: "OpenSans-Bold";
    color: #fff;
    transform: translate(-118px, -44px);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .register-animation {
    width: 30%;
  }

  .space-input {
    margin-bottom: 4rem;
  }

  .placehold { 
    font-family: "OpenSans-Bold";
    color: #fff;
    display: flex;
    max-width: fit-content;
    padding: 10px 2px;
    pointer-events: none;
    width: 100%;
    position: absolute;
    transform: translate(170px);
  }

  .short-placehold {
    display: none;
  }

  .img-select {
    font-family: 'OpenSans-Bold';
    color: #fff;
    transform: translate(-70px, -45px);
    display: flex;
    max-width: 280px;

    &:hover {
        cursor: pointer;
  }

  .img-select-icon {
    width: 25px;
    margin-right: 6px;
    fill: white;
  }
}

  @media only screen and (max-width: 900px) {
    .register-animation {
      position: absolute;
      bottom: 0;
      background: transparent;
      opacity: 0.5;
      width: unset;
    }

    .register-fields {
      width: 100%;
      z-index: 1;
    }
  }

  @media screen and (max-width: 460px) {
    .login-redirect-register {
      font-size: 12px;
      transform: unset;
      margin-bottom: 20px;
    }

    .register-instruction {
      display: none;
    }

    .register {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .placehold {
      display: none;
    }

    .short-placehold {
      display: flex;
    }

    .email-register {
      width: 100%;
    }
  }
}
