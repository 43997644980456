.inbox-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 36px;

    .options-filter {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
        border: none;
        border-radius: 6px;
        background: var(--app-bg);
        max-width: 135px;
        min-height: 34px;

        .option {
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 2px;
            cursor: pointer;
        }

        .pressed {
            // background-color: var(--box-color);
            border-radius: 6px;
            box-shadow: inset 0 0 2px 2px var(--info-box-color-1);
        }


        .icon {
            width: 1.2rem;
            fill: var(--text-dark);
        }
    }

    .search-input {
        // width: 400px;
        max-width: 400px;
        padding-left: 16px;
    }
}

.inbox-body {
    display: flex;
    height: calc(100% - 84px);
    flex-direction: row;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

}

.container-message-open {
    flex: 1;
    height: 100%;
    margin-left: 1rem;

    .container-info {
        text-align: center;
        flex: 1;
    }

    .info {
        color: var(--text-dark);
        cursor: unset !important;
    }

    .msg-open {
        height: 100%;
        overflow-y: scroll;
        background-color: var(--message-box-2);
        border-radius: 6px;
    }


    .date-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        color: var(--text-light);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
    }

    .subject-message {
        padding-left: 2rem;
        padding-right: 2rem;
        color: var(--text-dark);
    }

    .icon {
        fill: var(--text-light);
        width: 1rem;
        cursor: pointer;
    }

    .option-msg {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .of-message {
        margin-bottom: 1rem;
        color: var(--text-light);
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .body-message {
        color: var(--text-dark);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }

}

.inbox-wrapper {
    height: 100%;
    overflow-y: scroll;

    .no-msgs {
        color: var(--text-dark);
    }

    .message {
        border-radius: 10px;
        width: 18.75rem;
        padding: 1rem;
        background-color: var(--message-box-2);
        margin-bottom: 1rem;
        cursor: pointer;

        .sender {
            display: flex;
            flex-direction: row;
            align-items: center;

            .avatar {
                object-fit: cover;
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            .name {
                margin-left: 1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--text-dark);
            }


        }

        .subject {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--text-dark);
        }

        .options {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .icons {
                align-items: center;
            }

            .star {
                width: 1.2rem;
                fill: var(--text-dark);
                margin-right: 1rem;
            }

            .date {
                color: #ccc;
            }

            .book {
                width: 1.2rem;
                fill: var(--text-dark);
            }


        }
    }
}

.un-button {
    background-color: unset;
    cursor: pointer;
    border: none;
    width: auto;
}

.info {
    cursor: help !important;
}

.pagination {
    padding-top: 1rem;
}

@media (max-width: 658px) {
    .search-input {
        padding-left: unset !important;
        padding-top: 1rem;
    }
    .inbox-wrapper {
        margin-top: 3rem;
    }
    .inbox-wrapper {
        height: 80%;
        width: 100%;
        
        .message {
            width: 90%;
        } 
    }

    .container-message-open {
        display: none
    }

    .container-column {
        width: 100%;
    }

    .container-message-open {
        height: 90%;
        margin-top: 3rem;
        margin-left: unset;
    }
    .disappear {
        display: none !important;
    }
    
    .appear {
        display: unset !important;
    }
}

.container-column {
    height: 100%;
    display: flex;
    flex-direction: column;
}

// .disappear {
//     display: none !important;
// }

// .appear {
//     display: unset !important;
// }