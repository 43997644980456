.editor {
    border: none;
    outline: none;
    height: 32px;
    flex: 1;
    margin-right: 4px;
    background-color: var(--chat-input);
    color: var(--text-dark);

    &:placeholder {
        color: var(--text-light);
        font-size: 12px;
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: var(--box-shadow);
        margin-top: auto;
        border-radius: 6px;
        padding: 12px;
        background-color: var(--chat-input);
        margin-bottom: 10px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
  }
  
  .editor :global(.public-DraftEditor-content) {
    min-height: 32px;
    // border: none;
    // outline: none;
    // height: 32px;
    // flex: 1;
    // margin-right: 4px;
    // background-color: var(--chat-input);
    // color: var(--text-dark);
  }
  