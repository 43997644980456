@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

.body-chat {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--app-bg);
  font-family: "DM Sans", sans-serif;
  --text-dark: #1a2f45;
  --text-light: #445e78;
  --chat-box-dark: #1b243b;
  --chat-box-light: #fff;
  --button-text: #fff;
  --toggle-ball: #fff;
  --list-item-active: #e5effb;
  --number-bg: #6895a3;
  --message-box-1: #fff;
  --message-box-2: #1a233b;
  --system-message: rgba(0, 0, 0, 0.2);
  --system-message-color: #8187a2;
  --chat-input: #060415;
  --border-light: #8187a2;
  --info-box-color-1: #004dfc;
  --info-box-color-2: #00ba9d;
  --info-box-color-3: #715fc2;
  --info-box-color-4: #ff562d;
  --info-icon-bg-1: #b1c7fc;
  --info-icon-bg-2: #c6f0ea;
  --info-icon-bg-3: #d9d3ff;
  --info-icon-bg-4: #ffe1d4;
  --app-bg: #060415;
  --box-color: #12172d;
  --box-border: #477eff;
  --button-bg: #477eff;
  --text-dark: rgba(255, 255, 255, 1);
  --text-light: rgba(255, 255, 255, 0.6);
  --info-box-1: rgba(160, 186, 242, 0.5);
  --info-box-2: rgba(168, 240, 229, 0.55);
  --info-box-3: rgba(194, 168, 240, 0.34);
  --info-box-4: rgba(240, 185, 168, 0.34);
  --toggle-bg: #477eff;
  --toggle-bg-off: #6895a3;
  --message-box-2: #477eff;
  --message-box-1: #576c99;
  --logo-path-1: #477eff;
  --logo-path-2: #576c99;
  --box-shadow: rgba(18, 23, 45, 0.6) 0px 8px 24px;
  --scrollbar-thumb: linear-gradient(
    to bottom,
    rgba(43, 88, 118, 0.8),
    rgba(78, 67, 118, 0.8)
  );

  &[data-theme="indigo"],
  &[data-theme="pink"] {
    --app-bg: #fff;
    --box-color: #f5f8fc;
    --box-border: #e7edf5;
    --text-dark: #1a2f45;
    --text-light: #445e78;
    --chat-box-dark: #1b243b;
    --chat-box-light: #fff;
    --button-bg: #004dfc;
    --button-text: #fff;
    --toggle-bg: #004dfc;
    --toggle-bg-off: #6895a3;
    --toggle-ball: #fff;
    --logo-path-1: #a0c9e1;
    --logo-path-2: #18689c;
    --list-item-active: #e5effb;
    --number-bg: #6895a3;
    --message-box-1: #fff;
    --message-box-2: #1a233b;
    --chat-input: #f5f8fc;
    --border-light: #e5e6eb;
    --info-box-1: rgba(217, 228, 252, 1);
    --info-box-2: rgba(226, 246, 243, 1);
    --info-box-3: #f7f3ff;
    --info-box-4: #fff1e9;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --info-box-3: #b3a5ce;
    --info-box-4: #ffceb3;
    --scrollbar-thumb: linear-gradient(
      to top,
      rgba(131, 164, 212, 0.5),
      rgb(182, 188, 255, 0.4)
    );
  }

  &[data-theme="pink"] {
    --box-color: #f8f1f1;
    --button-bg: #1a233b;
    --logo-path-1: #e8d5db;
    --logo-path-2: #6895a3;
    --toggle-bg: #1a233b;
  }

  &[data-theme="navy-dark"] {
    --app-bg: #060415;
    --box-color: #12172d;
    --box-border: #477eff;
    --button-bg: #477eff;
    --toggle-bg: #477eff;
    --toggle-bg-off: #6895a3;
    --message-box-2: #477eff;
    --message-box-1: #576c99;
    --chat-input: #060415;
    --border-light: #8187a2;
  }

  &[data-theme="navy-dark"],
  &[data-theme="dark"] {
    --text-dark: rgba(255, 255, 255, 1);
    --text-light: rgba(255, 255, 255, 0.6);
    --info-box-1: rgba(160, 186, 242, 0.5);
    --info-box-2: rgba(168, 240, 229, 0.55);
    --info-box-3: rgba(194, 168, 240, 0.34);
    --info-box-4: rgba(240, 185, 168, 0.34);
    --logo-path-1: #477eff;
    --logo-path-2: #576c99;
    --scrollbar-thumb: linear-gradient(
      to bottom,
      rgba(43, 88, 118, 0.8),
      rgba(78, 67, 118, 0.8)
    );
  }

  &[data-theme="dark"] {
    --app-bg: #040508;
    --box-color: #131a24;
    --box-border: #131a24;
    --button-bg: #1e2b4a;
    --toggle-bg: #477eff;
    --toggle-bg-off: #6895a3;
    --message-box-2: #1e2b4a;
    --message-box-1: #576c99;
    --chat-input: #040508;
    --border-light: #040508;
  }
}

.app-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.app-left {
  flex-basis: 320px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin-right: 24px;
  overflow-y: auto;
}

.app-left-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--app-bg);

  h1 {
    margin: 0;
    font-size: 20px;
    color: var(--text-dark);
    margin-left: 12px;
  }
}

.app-logo img {
  width: 40px;
  height: 40px;

  .path-1 {
    fill: var(--logo-path-1);
  }

  .path-2 {
    fill: var(--logo-path-2);
  }
}

.app-profile-box {
  border: 1px solid var(--box-border);
  background-color: var(--box-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 24px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }

  &-name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--text-dark);
    margin: 4px 0;
    display: flex;
    align-items: center;
  }

  &-title {
    font-size: 12px;
    line-height: 16px;
    color: var(--text-light);
    margin: 0;
  }

  &-info {
    font-family: monospace;
    font-size: 16px;
    font-weight: 700;
    color: var(--text-dark);
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;

    .icon-rank {
      width: 25px;
      height: 25px;
      margin-left: 5px;
      fill: #943d12;
    }

    .icon-coins {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    .icon-coins > circle {
      fill: #b8860b;
      stroke: #ffd700;
      stroke-width: 10;
    }

    .icon-coins > svg {
      fill: #ffd700;
    }

    &-rank {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 14px;
      margin-right: 10px;
      border-radius: 6px;
      background-color: var(--app-bg);
    }

    &-coins {
      display: flex;
      align-items: center;
      padding: 8px 14px;
      border-radius: 6px;
      background-color: var(--app-bg);
    }
  }
}

.switch-status {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.switch-status input {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;

  &:checked + label {
    background-color: var(--toggle-bg);

    &:before {
      left: 12px;
    }
  }

  &:checked ~ .toggle-offline {
    display: none;
  }

  &:checked ~ .toggle-online {
    display: inline-block;
  }
}

.toggle-text {
  user-select: none;
  color: var(--text-light);
}

.toggle-online {
  display: none;
}

.toggle-offline {
  display: inline-block;
}

.label-toggle {
  background-color: var(--toggle-bg-off);
  border-radius: 24px;
  width: 24px;
  height: 14px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  transition: 0.2s;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 2px;
    top: 50%;
    transform: translatey(-50%);
    border-radius: 50%;
    background-color: var(--toggle-ball);
    transition: all 0.2s ease;
  }
}

.toggle-text {
  font-size: 12px;
  line-height: 16px;
}

.app-setting {
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--text-dark);
  margin-left: 4px;
}

.icon-menu {
  width: 1.5rem;
  fill: var(--text-dark);
  margin-right: 1rem;
}

.chat-list {
  padding: 0;
  list-style: none;
  height: 0px;
  overflow: hidden;
  transition: 0.4s ease-in;
  display: none;
  opacity: 0;

  &-active {
    display: block;
    height: auto;
    max-height: auto;
    opacity: 1;
  }

  &-item {
    transition: 0.2s;
    padding: 8px 14px;
    margin: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    &.active {
      background-color: var(--box-color);

      span {
        color: var(--text-dark);
        font-weight: 700;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 8px;
    }

    &:hover {
      background-color: var(--box-color);
    }
  }

  &-name {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: var(--text-light);
  }

  &-header {
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
    color: var(--text-dark);
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;

    .c-number {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--button-bg);
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-left: 8px;
    }
  }

  &-wrapper {
    height: 100%;
    padding: 0;
  }
}

.list-header-arrow {
  margin-left: auto;
  transform: rotate(180deg);
  transition: 0.2s ease;

  .chat-list-header.active & {
    transform: rotate(0deg);
  }
}

.app-main {
  flex: 3;
  height: 100%;
  background-color: var(--box-color);
  border-radius: 0px;
  // margin-top: 16px;
  // margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;

  &-header {
    position: sticky;
    top: 0;
    background-color: var(--box-color);
    border: 1px solid var(--box-border);
  }
}

.chat-wrapper {
  overflow: auto;
  padding: 1rem;
  position: relative;
  height: 100%;

  .loader-more-msg {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
  }
}

.chat-input {
  border: none;
  outline: none;
  height: 32px;
  flex: 1;
  margin-right: 4px;
  background-color: var(--chat-input);
  color: var(--text-dark);

  &:placeholder {
    color: var(--text-light);
    font-size: 12px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--box-shadow);
    margin-top: auto;
    border-radius: 6px;
    padding: 12px;
    background-color: var(--chat-input);
    margin-bottom: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

// .chat-input--singleLine {
//     // width: 100%;
//     overflow: hidden !important;
//     overflow-y: hidden !important;

//     input {
//         border: none;
//         outline: none;
//         display: block;
//         height: 32px;
//         flex: 1;
//         margin-right: 4px;
//         background-color: var(--chat-input);
//         color: var(--text-dark);
//         overflow: hidden !important;
//     }

//     &:placeholder {
//         color: var(--text-light);
//         font-size: 12px;
//     }

//     // &-wrapper {
//     //     display: flex;
//     //     justify-content: space-between;
//     //     align-items: center;
//     //     box-shadow: var(--box-shadow);
//     //     margin-top: auto;
//     //     border-radius: 6px;
//     //     padding: 12px;
//     //     background-color: var(--chat-input);
//     //     margin-bottom: 10px;
//     //     margin-left: 1rem;
//     //     margin-right: 1rem;
//     // }
// }

// .chat-input__highlighter--singleLine {
//     display: contents !important;
//     strong {
//         border-top: 3px solid #6D58FF !important;
//         color: transparent !important;
//         top: 7px !important;
//         position: relative !important;
//     }
// }
// .chat-input__suggestions {
//     background-color: #6D58FF !important;
//     height: 100px !important;
//     margin-left: 5px !important;
//     ul {
//         li {
//             color: white !important;
//         }
//     }
// }

.input-wrapper {
  border: 1px solid var(--border-light);
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  flex: 1;
  overflow: hidden !important;
  padding: 0 6px 0 12px;
  justify-content: space-between;
  margin: 0 8px;
  background-color: var(--chat-input);
}

.emoji-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  color: #ffca3e;
}

.sugestion-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.chat-send-btn {
  height: 32px;
  color: #fff;
  background-color: var(--button-bg);
  border: none;
  border-radius: 4px;
  padding: 0 32px 0 10px;
  font-size: 12px;
  background-position: center right 8px;
  background-repeat: no-repeat;
  background-size: 14px;
  line-height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cdefs/%3E%3Cpath fill='%23fff' d='M481.508 210.336L68.414 38.926c-17.403-7.222-37.064-4.045-51.309 8.287C2.86 59.547-3.098 78.551 1.558 96.808L38.327 241h180.026c8.284 0 15.001 6.716 15.001 15.001 0 8.284-6.716 15.001-15.001 15.001H38.327L1.558 415.193c-4.656 18.258 1.301 37.262 15.547 49.595 14.274 12.357 33.937 15.495 51.31 8.287l413.094-171.409C500.317 293.862 512 276.364 512 256.001s-11.683-37.862-30.492-45.665z'/%3E%3C/svg%3E");
}

.chat-attachment-btn {
  border: none;
  padding: 0;
  background-color: transparent;
  color: var(--text-light);
  display: flex;
  align-items: center;
  opacity: 0.7;
}

.system-message {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
  text-align: center;
}

.system-message-box {
  width: auto;
  margin: auto;
  padding: 5px 10px 5px 10px;
  max-width: 80%;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  white-space: 'pre-line';
  word-break: break-all !important;
  box-shadow: var(--box-shadow);
  color: var(--system-message-color);
  background-color: var(--system-message);
}

.message-wrapper {
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;

  &.reverse {
    justify-content: flex-end;

    .message-box {
      background-color: var(--message-box-2);
      color: #fff;

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .message-pp {
      order: 2;
    }
  }
}

.message-pp {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.message-box {
  background-color: var(--message-box-1);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  width: auto;
  max-width: 90%;
  color: var(--text-dark);
  position: relative;

  &-wrapper {
    margin: 0 12px;

    span {
      font-size: 10px;
      line-height: 16px;
      color: var(--text-light);
      opacity: 0.6;
    }
  }

  .replay {
    top: -8px;
    z-index: 1;
    width: 15px;
    right: -6px;
    padding: 4px;
    height: 15px;
    display: none;
    cursor: pointer;
    padding-left: 5px;
    border-radius: 4px;
    padding-right: 5px;
    position: absolute;
    background-color: #0075bf;
  }

  &:hover {
    .replay {
      display: block;
    }
  }
}

.message-box-reply {
  background-color: rgba(71, 126, 255, 0.2);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  width: auto;
  color: white;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgb(71, 126, 255);
  border-right: 5px solid rgb(71, 126, 255);
}

.message-box-reply-reverse {
  border: 1px solid rgb(71, 126, 255);
  border-left: 5px solid rgb(71, 126, 255);
}

.app-right {
  flex-basis: 320px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  transition: 0.2s;
  overflow: auto;
  padding-top: 1rem !important;

  .app-profile-box img {
    margin-bottom: 16px;
  }

  .app-profile-box-title {
    width: 100%;
    color: var(--text-dark);
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      margin-right: 6px;
    }

    .jail-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .jail {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      // overflow: hidden;
      // margin-right: 1rem;
    }

    .box-jail {
      margin-bottom: unset;
      border-end-end-radius: unset;
      border-end-start-radius: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .accordion-content {
      color: #fff;
      // background-color: white;
      // border-left: 1px solid whitesmoke;
      // border-right: 1px solid whitesmoke;
      // padding: 0 20px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;
      overflow: auto;
    }
  }
}

.archive-btn {
  color: #fff;
  height: 32px;
  margin-top: 16px;
  border-radius: 4px;
  background-color: var(--button-bg);
  display: flex;
  align-items: center;
  font-size: 14px;
  border: none;

  svg {
    margin-left: 6px;
  }
}

.box-jail,
.app-activity-box {
  height: auto;
  border-radius: 10px;
  max-height: 100%;
  padding: 16px 16px 8px 16px;
  margin-bottom: 24px;
  border: 1px solid var(--box-border);
  background-color: var(--box-color);
  overflow: auto;
}

.activity-info-boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.activity-info-box {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  &.logged-out {
    opacity: 0.8;
    background-color: var(--info-box-1);
    color: var(--info-box-color-1);

    .info-icon-wrapper {
      background-color: var(--info-icon-bg-1);
    }
  }

  &.logged {
    background-color: var(--info-box-2);
    color: var(--info-box-color-2);

    .info-icon-wrapper {
      background-color: var(--info-icon-bg-2);
    }
  }
}

.card-jail {
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--info-box-2);
}

.activity-info-box > svg {
  .mention-item {
    //   width: 100%;
    //   border-radius: 10px;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--toggle-bg);
    }
  }
}

.mention-item {
  //   width: 100%;
  //   border-radius: 10px;
  padding: 10px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--toggle-bg);
  }
}

.activity-info-box > svg {
  .mention-item {
    //   width: 100%;
    //   border-radius: 10px;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--toggle-bg);
    }
  }

  .activity-info-box > svg {
    margin-right: 10px;
  }
}

.info-icon-wrapper {
  border-radius: 50%;
  margin-right: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }
}

.info-text-wrapper {
  margin-left: 12px;
}

.info-text-wrapper span {
  display: block;
}

.info-text-upper {
  font-size: 14px;
  font-weight: 700;
}

.info-text-bottom {
  font-size: 10px;
  color: var(--text-light);
}

.activity-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-top: 16px;
}

.info-header-bold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--text-dark);
}

.info-header-light {
  color: var(--text-light);
  font-size: 12px;
  line-height: 24px;
}

.activity-days-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 64px;

  &.current .chart {
    opacity: 1;
    transform: scale(1.2);
    transform-origin: bottom;
  }

  &:first-child {
    .chart {
      height: 20%;
    }
  }

  &:nth-child(3) .chart {
    height: 100%;
  }

  .chart {
    border-radius: 6px;
    height: 50%;
    width: 6px;
    background-color: var(--button-bg);
    opacity: 0.5;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background-color: var(--button-bg);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: calc(100% + 4px);
    }
  }

  span {
    margin-top: 20px;
    display: block;
    font-size: 10px;
    color: var(--text-light);
  }
}

.app-right-bottom {
  position: fixed;
  bottom: 0;
  right: 24px;
  z-index: 1;
  background-color: var(--app-bg);
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: 10px;
}

.app-theme-selector {
  background-color: var(--box-color);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--box-border);
  display: flex;
  justify-content: flex-end;
  width: auto;
  padding: 10px;
  border-radius: 6px;
}

.theme-color {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #fff;
  cursor: pointer;

  &.active {
    border: 1px solid rgba(71, 126, 255, 1);
    box-shadow: 0 0 0 3px rgba(71, 126, 255, 0.2);
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &.indigo {
    background-color: #18689c;
  }

  &.pink {
    background-color: #e8d5db;
  }

  &.dark {
    background-color: #060415;
  }

  &.navy-dark {
    background-color: #192734;
    color: #fff;
  }
}

.open-left,
.open-right {
  position: absolute;
  padding: 0;
  display: none;
  width: 20px;
  height: 20px;
  background-color: var(--box-color);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--box-border);
  border-radius: 4px;
  top: 24px;
}

.open-left {
  left: 0;
}

.open-right {
  right: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--scrollbar-thumb);
}

@media screen and (max-width: 1150px) {
  .app-left {
    display: none;
    transform: translateX(-100%);
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);

    &.open {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .open-left {
    display: block;
  }

  .app-main {
    height: calc(100% - 100px);
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 810px) {
  .app-right {
    display: none;
    transform: translateX(100%);
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);

    &.open {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .open-right {
    display: block;
  }

  .app-theme-selector {
    position: fixed;
    bottom: 0;
  }

  // .app-main {
  //   height: 100%;
  // }

  // .app-container {
  //   height: 83% !important;
  // }
}

@media screen and (max-width: 540px) {
  .app-main {
    height: 84%;
  }
}

@media screen and (max-width: 460px) {
  .app-container {
    display: unset;
  }
}

.rank-boxes {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;

  .title {
    color: var(--text-dark);
    font-size: 1rem;
    font-family: "OpenSans-SemiBold";
  }

  @mixin podium-style {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 0.75rem;
  }

  .podium {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .first {
    width: 60px;
    height: 50px;
    background-color: #0075bf;
    @include podium-style();
  }

  .second {
    width: 60px;
    height: 35px;
    background-color: #0075bf;
    @include podium-style();
  }

  .third {
    width: 60px;
    height: 25px;
    background-color: #0075bf;
    @include podium-style();
  }
}
