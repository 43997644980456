.m-d.expand-list {
    margin: 0;
    padding: 0;

    label {
        color: var(--text-dark)
    }

}

.m-d.expand-list>li {
    list-style-type: none;
    padding: 15px 0;
    position: relative;
    /*         max-width: 80%; */
}

.m-d label[class^="tab"]:hover {
    cursor: pointer;
}

.m-d input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.m-d input[class^="tab"] {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
}

.m-d input[class^="tab"]:hover {
    cursor: pointer;
}

.m-d label[class^="tab"] {
    font-weight: bold;
}

.m-d .content {
    height: auto;
    max-height: 0;
    max-width: 300px;
    /*        background: yellow;*/
    overflow: hidden;
    transform: translateY(20px);
    transition: all 180ms ease-in-out 0ms;
}

.m-d li[data-md-content="100"] input[class^="tab"]:checked~.content {
    max-height: 100px;
    transition: all 150ms ease-in-out 0ms;
}

.m-d li[data-md-content="200"] input[class^="tab"]:checked~.content {
    max-height: 200px;
    transition: all 200ms ease-in-out 0ms;
}

.m-d li[data-md-content="300"] input[class^="tab"]:checked~.content {
    max-height: 300px;
    transition: all 250ms ease-in-out 0ms;
}

.m-d li[data-md-content="400"] input[class^="tab"]:checked~.content {
    max-height: 400px;
    transition: all 250ms ease-in-out 0ms;
}

.m-d li[data-md-content="500"] input[class^="tab"]:checked~.content {
    max-height: 500px;
    transition: all 250ms ease-in-out 0ms;
}

.m-d li[data-md-content="600"] input[class^="tab"]:checked~.content {
    max-height: 600px;
    transition: all 250ms ease-in-out 0ms;
}

.m-d li[data-md-content="700"] input[class^="tab"]:checked~.content {
    max-height: 700px;
    transition: all 300ms ease-in-out 0ms;
}

.m-d li[data-md-content="800"] input[class^="tab"]:checked~.content {
    max-height: 800px;
    transition: all 300ms ease-in-out 0ms;
}

.m-d li[data-md-content="900"] input[class^="tab"]:checked~.content {
    max-height: 900px;
    transition: all 300ms ease-in-out 0ms;
}

.m-d li[data-md-content="1000"] input[class^="tab"]:checked~.content {
    max-height: 1000px;
    transition: all 350ms ease-in-out 0ms;
}

.m-d li[data-md-content=""] input[class^="tab"]:checked~.content {
    max-height: 1000px;
    transition: all 250ms ease-in-out 0ms;
}

.m-d input[class^="tab"]:checked~.content {
    margin-bottom: 20px;
}

.m-d .open-close-icon {
    display: inline-block;
    position: absolute;
    right: 20px;
    transform: translatey(2px);
}

.m-d .open-close-icon i {
    position: absolute;
    left: 0;
}

.m-d .open-close-icon .fa-minus {
    transform: rotate(-90deg);
    transition: transform 150ms ease-in-out 0ms;
}

.m-d input[class^="tab"]:checked~.open-close-icon .fa-minus {
    transform: rotate(0deg);
    transition: transform 150ms ease-in-out 0ms;
}

.m-d .open-close-icon .fa-plus {
    opacity: 1;
    transform: rotate(-90deg);
    transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms;
}

.m-d input[class^="tab"]:checked~.open-close-icon .fa-plus {
    opacity: 0;
    transform: rotate(0deg);
    transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms;
}

.jailTimer {
    border: 1px solid darken(#576c99, 40);;
    background-color: darken(#576c99, 40);
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 14px;
}













// * {
//     line-height: 1.4;
//     font-family: "harmonia sans", roboto, arial;
// }