.flex-container {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #fff;
    align-items: center;

    .icon {
        fill: #fff;
    }

    .icon-container {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 2.188rem;
        margin-right: 1rem;
    }
}

.input {
    &-container {
        position: relative;
        height: 61px;
        flex: 1;
    }

    &-field {
        color: #fff;
        display: block;
        width: 100%;
        padding: 0.75rem;
        font-size: 1.5rem;
        outline: none;
        border: none;
        background-color: transparent;
        font-family: 'OpenSans-Regular';

        &.on-focus+.input-label p {
            transform: translateY(-50px);

        }

        &:disabled {
            background: #ccc;
            cursor: not-allowed;
            height: 40px;
            bottom: 0;
            position: absolute;
        }
    }

    &-label {
        display: flex;
        position: absolute;
        top: 75%;
        transform: translate(15px, -70%);
        pointer-events: none;
        font-size: 1.25rem;
        font-family: "OpenSans-SemiBold";
        color: #fff;

        p {
            transition: transform 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
        }

        @for $i from 1 to 9 {
            & p:nth-child(#{$i}) {
                transition-delay: #{$i * 90}ms;
            }
        }
    }
}

.fa-secondary{
    opacity:.4
}