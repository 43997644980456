.input-filter {
    display: flex;
    align-items: center;
    // box-shadow: var(--box-shadow);
    border-radius: 6px;
    background-color: var(--chat-input);

    .icon {
        width: 0.75rem;
        height: 0.75rem;
        fill: var(--text-dark);
        ;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .wrapper {
        // box-shadow: var(--box-shadow);
        flex: 1;
    }

}