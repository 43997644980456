

.total-div{    
    overflow-y: scroll;
    // max-height: 100%;
}
    .space-form {
        width: 80%;
        margin: 40px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        color: #fff;
        padding-bottom: 50px;
    
        .title_message {
            align-self: flex-start;
            
            h1 {
                font-family: 'OpenSans-Bold';
                font-size: 30px;
            }
        }
    
        .campos_form {
            width: 100%;

            .space-input-message {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-family: 'OpenSans-Bold';
                font-size: 0.8rem;
                margin: 12px auto;

                label {
                    margin-bottom: 5px;
                }
    
                .text_campo_inputs {
                    height: 38px;
                }
    
                .error-msg {
                    color: #FC8181;
                }

                .error-msg-enviar {
                    margin-top: 2px;
                    margin-bottom: 0px;
                    font-size: 12px;
                }
            }
        }
        
        .space-button {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .div-button {
                margin: 0 10px;

                .button-voltar {
                    font-size: 14px;
                    font-weight: 500;
                    width: 160px;
                    height: 41px;
                    text-align: center;
                    color: #fff;
                    background-color: #1a2f45;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }

        .text_campo {
            font-family: 'OpenSans-Light';
            width: 100%;
            box-sizing: border-box;
            background-color: var(--chat-input);
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px;
    
            &::placeholder {
                color: #aaa;
            }
        }
    }
// }
@media screen and (max-width: 1150px) {
    .space-form {
        margin-bottom: 50px;
    }
    .app-container {
        display: unset;
    }
}

@media screen and (max-width: 600px) {
    .total-div {
        height: calc(100% - 100px);
    }
    .space-button {
        width: 100%;
    }
}

@media screen and (max-width: 460px) {
    .total-div {

        .space-form {
            width: 96%;
            margin-bottom: 200px;

            .space-button {
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                .div-button {
                    margin: 10px 0;
                }
            }
        }
    }
}