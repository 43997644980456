.modal-open {
    display: inline-block;
    border-radius: 10px;
    background: #4caf50;
    padding: 5px 15px;
    color: #fff;
}

.modal-label {
    position: relative;
}

.modal-radio {
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    transform: scale(0);
    transition: opacity .25s, transform .25s;
    z-index: 99;
}

.modal2 {
    transform: scale(0.8) translate(0, -100%);
    transition: transform .35s, opacity .35s;
}

.modal3 {
    transform: translate(0, 100%) scale(.9);
}

.modal4 {
    transform: scale(0) rotate(180deg);
}

#modal-open:checked+.modal {
    opacity: 1;
    transform: scale(1);
}

#modal-open2:checked+.modal2 {
    transform: scale(1) translate(0, 0);
    opacity: 1;
}

#modal-open3:checked+.modal3 {
    transform: scale(1) translate(0, 0);
    opacity: 1;
}

#modal-open4:checked+.modal4 {
    transform: scale(1) rotate(0deg);
    opacity: 1;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.modal .content {
    color: black;
    position: absolute;
    width: 70%;
    height: 300px;
    max-height: 70%;
    top: 10%;
    left: 15%;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 3px 15px -2px #222;
    padding: 20px;

    .footer {
        display: flex;
        flex: 1;
        /* height: 100%; */
        width: 100%;
        text-align: end;
        height: 100%;
        /* align-items: normal; */
        flex-direction: row;
        justify-content: flex-end;
    }

    .top {
        :first-child{
            margin-top: unset;
        }
    }
}

.close-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 20px;
    border-radius: 50%;
    border: 2px solid #f44336;
    cursor: pointer;
}

.close-btn:after,
.close-btn:before {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    background: #f44336;
    left: 15%;
    top: 50%;
    top: calc(50% - 1px);
    transform-origin: 50% 50%;
    transform: rotate(45deg);
}

.close-btn:before {
    transform: rotate(-45deg);
}

.action-btn {
    display: inline-block;
    padding: 10px 20px;
    background: #4caf50;
    color: #fff;
    border-radius: 15px;
}